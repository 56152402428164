/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-loops/no-loops */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { Badge } from 'reactstrap'
import { AbilityContext } from 'utils/context/can'
import { BadgeNPS, BadgeRightStyled } from './styles'

/* eslint-disable no-unused-vars */
export const isNavLinkActive = (link: any, currentURL: any, routerProps: any) => (
  currentURL === link ||
  (routerProps && routerProps.meta && routerProps.meta.navLink && routerProps.meta.navLink === link)
)

export const isNavGroupActive = (children: any, currentURL: any, routerProps: any) => children.some((child: any) => {
  if (child.children) {
    return isNavGroupActive(child.children, currentURL, routerProps)
  }
  return isNavLinkActive(child.navLink, currentURL, routerProps)
})

export const search = (navigation: any, currentURL: any, routerProps: any) => {
  let result
  navigation.some((child: any) => {
    let children
    // If child have children => It's group => Go deeper(recursive)
    if (child.children && (children = search(child.children, currentURL, routerProps))) {
      result = {
        id: child.id,
        children
      }
      return result
    }

    // else it's link => Check for matched Route
    if (isNavLinkActive(child.navLink, currentURL, routerProps)) {
      result = {
        id: child.id
      }
      return result
    }
  })
  return result
}

export const getAllParents = (object: any, match: any) => {
  const res: any = []
  const recurse = (obj: any, _current?: any) => {
    if (obj) {
      Object.entries(obj).forEach(([key]) => {
        const value = obj[key]
        if (value !== undefined) {
          if (value && typeof value === 'object') {
            recurse(value, key)
          } else if (key === match) {
            res.push(value)
          }
        }
      })
    }
  }
  recurse(object)
  return res
}

export const canViewMenuGroup = (item: any) => {
  const ability = useContext(AbilityContext)
  // ! This same logic is used in canViewHorizontalNavMenuGroup and canViewHorizontalNavMenuHeaderGroup. So make sure to update logic in them as well
  const hasAnyVisibleChild = item.children && item.children.some((i: any) => ability.can(i.action, i.resource))

  // ** If resource and action is defined in item => Return based on children visibility (Hide group if no child is visible)
  // ** Else check for ability using provided resource and action along with checking if has any visible child
  if (!(item.action && item.resource)) {
    return hasAnyVisibleChild
  }
  return ability.can(item.action, item.resource) && hasAnyVisibleChild
}

export const canViewMenuItem = (item: any) => {
  const ability = useContext(AbilityContext)
  return ability.can(item.action, item.resource)
}

export const resolveVerticalNavMenuItemComponent = (item: any) => {
  if (item.header) return 'VerticalNavMenuSectionHeader'
  if (item.children) return 'VerticalNavMenuGroup'
  return 'VerticalNavMenuLink'
}

export const resolveHorizontalNavMenuItemComponent = (item: any) => {
  if (item.children) return 'HorizontalNavMenuGroup'
  return 'HorizontalNavMenuLink'
}

export const selectThemeColors = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const isObjEmpty = (obj: any) => Object.keys(obj).length === 0
export const getUserData = () => JSON.parse(localStorage.getItem('userData') || '')

export const copyClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
  toast.success('Copiado para área de transferência')
}

export const formatCpfCnpj = (document: string) => {
  if (!document) return ''
  if (document.length > 11) {
    let cnpj = `${document.substr(0, 2)}.${document.substr(2, 3)}.${document.substr(5, 3)}/`
    if (document.length > 12)
      cnpj += `${document.substr(8, 4)}-${document.substr(12, 2)}`
    else
      cnpj += document.substr(8)
    document = cnpj
  } else {
    let cpf = ''
    const parts = Math.ceil(document.length / 3)
    for (let i = 0; i < parts; i++) {
      if (i === 3) {
        cpf += `-${document.substr(i * 3)}`
        break
      }
      cpf += `${i !== 0 ? '.' : ''}${document.substr(i * 3, 3)}`
    }
    document = cpf
  }
  return document
}

export const getStatusText = (status: string) => {
  switch (status.toLowerCase()) {
    case 'completed':
    case 'released':
    case 'paid':
      return 'Recebido'
    case 'canceled':
      return 'Cancelado'
    case 'denied':
      return 'Negado'
    case 'internal-approval':
    case 'internalapproval':
      return 'Aguardando aprovação interna'
    case 'issued':
      return 'Emitido'
    case 'approved':
      return 'Aprovado'
    case 'pending':
      return 'Pendente'
    case 'companyapproval':
      return 'Aguardando aprovação empresa'
    case 'failed':
      return 'Falha'
    case 'notauthenticated':
      return 'Não autenticado'
    case 'waitingauthentication':
      return 'Aguardando autenticação'
    case 'waitingpayment':
      return 'Aguardando pagamento'
    default:
      return status
  }
}

export const getBadgeStatus = (status: string, func?: any) => {
  if (!status) {
    return <Badge color='light-primary' onClick={func}>Indefinido</Badge>
  }

  switch (status.toLowerCase()) {
    case 'completed':
    case 'released':
    case 'paid':
      return <Badge color='light-success' onClick={func}>Recebido</Badge>
    case 'canceled':
      return <Badge color='light-danger' onClick={func}>Cancelado</Badge>
    case 'denied':
      return <Badge color='light-danger' onClick={func}>Negado</Badge>
    case 'internal-approval':
    case 'internalapproval':
      return <Badge color='light-primary' onClick={func}>Aprovação interna</Badge>
    case 'issued':
      return <Badge color='light-primary' onClick={func}>Emitido</Badge>
    case 'approved':
      return <Badge color='light-success' onClick={func}>Aprovado</Badge>
    case 'pending':
      return <Badge color='light-warning' onClick={func}>Aguardando empresa</Badge>
    case 'companyapproval':
      return <Badge color='light-warning' onClick={func}>Aprovação empresa</Badge>
    case 'failed':
      return <Badge color='light-danger' onClick={func}>Falha</Badge>
    case 'notauthenticated':
      return <Badge color='light-danger' onClick={func}>Não autenticado</Badge>
    case 'waitingauthentication':
      return <Badge color='light-warning' onClick={func}>Aguardando autenticação</Badge>
    default:
      return <Badge color='light-primary' onClick={func}>{status}</Badge>
  }
}

const translateStatusReason = (statusReason: string): string => {
  if (statusReason.toLowerCase().includes('pld/cft')) return 'Aplicação cancelada pelo KYC/PLD/CFT da Celcoin'
  if (statusReason.toLowerCase().includes('expired')) return 'Aplicação expirada'
  return statusReason
}
export const getBadgeStatusDescritpion = (status: string, func?: any) => {
  if (!status) {
    return <Badge color='light-primary' onClick={func}>Indefinido</Badge>
  }
  return <Badge color='light-danger' onClick={func}>{translateStatusReason(status)}</Badge>
}

export const getBadgePersonStatus = (status: string, func?: any) => {
  switch (status.toLowerCase()) {
    case 'ativo':
      return <Badge color='light-success' onClick={func}>Ativo</Badge>
    case 'inativo':
      return <Badge onClick={func}>Inativo</Badge>
    case 'pre-cadastro':
      return <Badge color='light-primary' onClick={func}>Pré-cadastro</Badge>
    case 'pendente':
      return <Badge color='light-warning' onClick={func}>Pendente</Badge>
    case 'demitido':
      return <Badge color='light-danger' onClick={func}>Demitido</Badge>
    case 'bloqueado':
      return <Badge color='light-danger' onClick={func}>Bloqueado</Badge>
    case 'verificado':
      return <Badge color='light-info' onClick={func}>Verificado</Badge>
    case 'rejeitado':
      return <Badge color='light-danger' onClick={func}>Rejeitado</Badge>
    case 'revogado':
      return <Badge color='light-danger' onClick={func}>Acesso Revogado</Badge>
    default:
      return <Badge color='light-primary' onClick={func}>status</Badge>
  }
}

export const getBadgeProductStatus = (status: string, hasConsignmentRestrictedByPartner: boolean) => {
  if (hasConsignmentRestrictedByPartner)
    return <Badge color='light-danger'>Produto bloqueado por CLD/CFT</Badge>
  switch (status.toLowerCase()) {
    case 'ativo':
      return <Badge color='light-success'>Produto ativo</Badge>
    case 'inativo':
      return <Badge color='light-secondary'>Produto inativo</Badge>
    case 'bloqueado':
      return <Badge color='light-danger'>Produto bloqueado</Badge>
    default:
      return <Badge color='light-primary'> Produto {status}</Badge>
  }
}

export const getBadgeChannel = (channel: string, func?: any) => {
  switch (channel?.toLowerCase()) {
    case 'whatsapp':
      return <Badge color='light-success' onClick={func}>Whatsapp</Badge>
    case 'call':
      return <Badge color='light-warning' onClick={func}>Telefone</Badge>
    case 'meet':
      return <Badge color='light-primary' onClick={func}>Videoconferência</Badge>
    case 'chat':
      return <Badge color='light-success' onClick={func}>Chat no App</Badge>
    default:
      return <Badge color='light-primary' func={func}>Via Backoffice</Badge>
  }
}

export const getBadgeSchedulingStatus = (status: string, func?: any) => {
  switch (status.toLowerCase()) {
    case 'scheduled':
      return <Badge color="light-primary" onClick={func}>Agendado</Badge>
    case 'started':
      return <Badge color="light-secondary" onClick={func}>Iniciado</Badge>
    case 'no-show':
      return <Badge color="light-danger" onClick={func}>No Show</Badge>
    case 'canceled':
      return <Badge color="light-info" onClick={func}>Cancelado</Badge>
    case 'finished':
      return <Badge color="light-success" onClick={func}>Realizado</Badge>
    default:
      return <Badge color='light-primary' onClick={func}>status</Badge>
  }
}

export const getBadgePlannerStatus = (status: string, func?: any) => {
  switch (status.toLowerCase()) {
    case 'active':
      return <Badge color="light-success" onClick={func}>Ativo</Badge>
    case 'inactive':
      return <Badge color="light-danger" onClick={func}>Inativo</Badge>
    case 'pending':
      return <Badge color="light-info" onClick={func}>Pendente</Badge>
    default:
      return <Badge color='light-primary' onClick={func}>status</Badge>
  }
}

export const getBadgeProfile = (profile: string, func?: any) => {
  switch (profile.toLowerCase()) {
    case 'decisor':
      return <Badge color="light-primary" onClick={func}>Decisor</Badge>
    case 'decisor-assistido':
      return <Badge color="light-primary" onClick={func}>Decisor Assistido</Badge>
    case 'influenciador':
      return <Badge color="light-primary" onClick={func}>Influenciador</Badge>
    case 'operacional':
      return <Badge color="light-primary" onClick={func}>Operacional</Badge>
    default:
      return <Badge color='light-warning' onClick={func}>Perfil não encontrado</Badge>
  }
}

export const getBadgeMeetingStatus = (status: string, func?: any) => {
  switch (status.toLowerCase()) {
    case 'opened':
      return <Badge color="light-success" onClick={func}>Em aberto</Badge>
    case 'closed':
      return <Badge color="light-danger" onClick={func}>Fechado</Badge>
    default:
      return <Badge color='light-primary' onClick={func}>status</Badge>
  }
}

export const getBadgeStatusConsent = (status: string, func?: any) => {
  switch (status.toLowerCase()) {
    case 'rejected':
      return <Badge color="light-danger" onClick={func}>REJEITADO</Badge>
    case 'authorised':
      return <Badge color="light-success" onClick={func}>AUTORIZADO</Badge>
    case 'awaiting_authorization':
      return <Badge color="light-primary" onClick={func}>AGUARDANDO AUTORIZAÇÃO</Badge>
    default:
      return <Badge color='light-warning' onClick={func}>NÃO ENCONTRADO</Badge>
  }
}

export const getBadgeNPS = (note: number) => {
  switch (note) {
    case 10:
      return <BadgeNPS color='#1AB920'>10</BadgeNPS>
    case 9:
      return <BadgeNPS color='#56D31E'>9</BadgeNPS>
    case 8:
      return <BadgeNPS color='#95D32B'>8</BadgeNPS>
    case 7:
      return <BadgeNPS color='#C5D22E'>7</BadgeNPS>
    case 6:
      return <BadgeNPS color='#EDCD39'>6</BadgeNPS>
    case 5:
      return <BadgeNPS color='#FFCB3E'>5</BadgeNPS>
    case 4:
      return <BadgeNPS color='#FF945A'>4</BadgeNPS>
    case 3:
      return <BadgeNPS color='#FF945A'>3</BadgeNPS>
    case 2:
      return <BadgeNPS color='#FF755E'>2</BadgeNPS>
    case 1:
      return <BadgeNPS color='#FF595E'>1</BadgeNPS>
    default:
      return <BadgeNPS color='#FF504E'>?</BadgeNPS>
  }
}

export const getTypeAdvance = (type: string, func?: any) => {
  switch (type.toLowerCase()) {
    case 'paycheckadvance':
      return (<Badge color='light-primary' onClick={func}>Adiantamento de salário</Badge>)
    case 'thirteenthadvance':
      return (<Badge color='light-primary' onClick={func}>Adiantamento de 13º</Badge>)
    case 'investmentrecurring':
      return (<Badge color='light-primary' onClick={func}>Investimento automático</Badge>)
    case 'consignment':
      return (<Badge color='light-primary' onClick={func}>Consignado</Badge>)
    case 'lowestfee':
      return (<Badge color='light-primary' onClick={func}>Menor taxa</Badge>)
    case 'deal':
      return (<Badge color='light-primary' onClick={func}>Renegociação de dívidas</Badge>)
    case 'paymentbankingbillet':
      return (<Badge color='light-primary' onClick={func}>Pagamento de boletos</Badge>)
    case 'pillapointsredeem':
      return (<Badge color='light-primary' onClick={func}>Resgate Pilla Indica</Badge>)
    default:
      return (<Badge color='light-primary' onClick={func}>{type}</Badge>)
  }
}

export const getOrderType = (type: string) => {
  switch (type.toLowerCase()) {
    case 'paycheckadvance':
      return 'Adiantamento de salário'
    case 'thirteenthadvance':
      return 'Adiantamento de 13º'
    case 'investmentrecurring':
      return 'Investimento automático'
    case 'consignment':
      return 'Crédito consignado'
    case 'lowestfee':
      return 'Adiantamento com a menor taxa'
    case 'deal':
      return 'Renegociação de dívidas'
    case 'paymentbankingbillet':
      return 'Pagamento de boletos'
    case 'pillapointsredeem':
      return 'Resgate Pilla Indica'
    default:
      return type
  }
}

export const getIsRenegotiationBadge = (usedForRenegotiation: boolean) => {
  if (usedForRenegotiation) {
    return (<Badge color='light-danger' >Sim</Badge>)
  }
  return (<Badge color='light-primary'>Não</Badge>)
}

export const getLabelProducts = (product: string, installmentNumber: number, totalInstallments: number) => {
  if (product.toLowerCase() === 'paycheckadvance') {
    let installments: React.ReactNode = ''
    if (totalInstallments > 1) {
      installments = <span>({installmentNumber}/{totalInstallments})</span>
    }
    return (<span>Adiantamento de Salário {installments}</span>)

  } if (product.toLowerCase() === 'consignment') {
    let installments: React.ReactNode = ''
    if (totalInstallments > 1) {
      installments = <span>({installmentNumber}/{totalInstallments})</span>
    }
    return (<span>Crédito Consignado {installments}</span>)
  } if (product.toLowerCase() === 'investmentrecurring') {
    return (<span>Investimento Automático</span>)
  } if (product.toLowerCase() === 'thirteenthadvance') {
    return (<span>Adiantamento de 13º</span>)
  } if (product.toLowerCase() === 'refunded') {
    return (<span>Reembolsado</span>)
  }
  return (<span>{product}</span>)
}

export const formatPhoneNumber = (number: string) => {

  if (number && number.length > 0) {
    const cleaned = number.replace(/\D/g, '')

    if (cleaned.length === 11) {
      const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`
      }
    } else if (cleaned.length === 10) {
      const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/)
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`
      }
    } else if (cleaned.length === 12) {
      const match = cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/)
      if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
      }
    }
  }
  return ''
}

export const formatDocument = (document: string | undefined) => {
  if (document?.length === 11) {
    const cpf = document.replace(/[^\d]/g, '')
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }

  if (document?.length === 14) {
    const cnpj = document.replace(/[^\d]/g, '')
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }

  return document
}

export const formatMoney = (amount: number | undefined) => {
  if (amount) {
    return amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  return amount === 0 ? 'R$ 0,00' : 'R$ -'
}

export const formatDate = (date: Date | string | undefined) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY')
  }
  return 'Não informado'
}

export const formatDateTime = (date: Date | string | undefined) => {
  if (date) {
    return moment.utc(date).format('DD/MM/YYYY [às] HH:mm')
  }
  return 'Não informado'
}

export const formatUtcDate = (date: Date | string | undefined) => {
  if (date) {
    return moment.utc(date).format('DD/MM/YYYY')
  }
  return 'Não informado'
}

export const getProductName = (product: string) => {
  switch (product.toLowerCase()) {
    case 'paycheckadvance':
      return 'Adiantamento de salário'
    case 'thirteenthadvance':
      return 'Adiantamento de 13º'
    case 'workshop':
      return 'Oficinas'
    case 'courses':
      return 'Cursos'
    case 'financeadvisor':
      return 'Consultoria financeira'
    case 'investment':
      return 'Cofrinho automático'
    case 'consignment':
      return 'Crédito consignado'
    case 'debtrenegotiation':
      return 'Renegociação de dívidas'
    case 'paymentbankingbillet':
      return 'Pagamento de boletos'
    default:
      return product
  }
}

export const getProductStatus = (status: string, func?: any) => {
  switch (status.toLowerCase()) {
    case 'ativo':
      return <Badge color="light-success" onClick={func}>Ativo</Badge>
    case 'inativo':
      return <Badge color="light-danger" onClick={func}>Inativo</Badge>
    default:
      return <Badge color='light-primary' onClick={func}>status</Badge>
  }
}

export const getCompanyType = (type: string | undefined) => {
  if (type === 'Group') return 'Grupo'
  if (type === 'Matrix') return 'Matriz'
  return 'Desconhecido'
}

export const getTypeFinancialDiagnosis = (type?: string) => {
  if (!type) {
    return <BadgeRightStyled color='light-secondary'>Não informado</BadgeRightStyled>
  }
  switch (type) {
    case 'Poupador':
      return (<BadgeRightStyled color='light-primary'>Poupador</BadgeRightStyled>)
    case 'Endividado':
      return (<BadgeRightStyled color='light-danger'>Endividado</BadgeRightStyled>)
    case 'Impulsivo':
      return (<BadgeRightStyled color='light-warning'>Impulsivo</BadgeRightStyled>)
    default:
      return (<BadgeRightStyled color='light-disabled'>Não informado</BadgeRightStyled>)
  }
}

export const getBadgeIssueStatus = (status: string) => {
  switch ((status ?? '').toLowerCase()) {
    case 'closed':
      return <Badge color='light-success'>Resolvido</Badge>
    case 'active':
      return <Badge color='light-secondary'>Sob investigação</Badge>
    case 'new':
      return <Badge color='light-danger'>Na fila</Badge>
    default:
      return <Badge color='light-primary'>{status}</Badge>
  }
}

export const getFinancialSchedulingReasonLabel = (reason: string) => {
  switch (reason.toLowerCase()) {
    case 'organizemonthlybudget':
      return 'Organizar meu orçamento mensal'
    case 'familyfinancialplanning':
      return 'Fazer planejamento em família'
    case 'realestatepurchasequestions':
      return 'Dúvidas sobre financiamento/consórcio de imóvel'
    case 'vehiclepurchasequestions':
      return 'Dúvidas sobre financiamento/consórcio de veículos'
    case 'debtassistance':
      return 'Ajuda com endividamento'
    case 'startsaving':
      return 'Começar a  economizar'
    case 'wanttoinvest':
      return 'Quero investir'
    case 'otherreasons':
      return 'Outros motivos'

    default:
      return reason
  }
}

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str
  }
  return `${str.slice(0, maxLength)}...`
}

export const convertArrayOfObjectsToCSV = (items: Array<any>) => {
  let result: any

  const columnDelimiter = ','
  const lineDelimiter = '\n'
  const keys = Object.keys(items[0])

  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  items.forEach(item => {
    let ctr = 0
    keys.forEach(key => {
      if (ctr > 0) {
        result += columnDelimiter
      }

      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export const getPayrollStatus = (status: string | undefined) => {
  if (status === undefined) return ''
  switch (status.toLowerCase()) {
    case 'close':
      return 'Fechada'
    case 'active':
      return 'Ativa'
    case 'open':
      return 'Aberta'
    default:
      return status
  }
}

export const getCompetencyName = (competency: string | undefined) => {
  if (competency === undefined) return ''
  const month = parseInt(competency.substring(4), 10) - 1
  const year = parseInt(competency.substring(0, 4), 10)
  const date = new Date(year, month)
  const monthName = date.toLocaleDateString('pt-BR', { month: 'long' }).replace(/^\w/, (c) => c.toUpperCase())

  return `${monthName} de ${year} (${competency.substring(4)}/${competency.substring(0, 4)})`
}

export const getBankingBilletStatus = (status: string | undefined) => {
  if (status === undefined) return ''
  switch (status.toLowerCase()) {
    case 'paid':
      return 'Pago'
    case 'pending':
      return 'Em Processamento'
    case 'waiting':
      return 'Em Aberto'
    case 'expired':
      return 'Vencido'
    case 'failed':
      return 'Falha'
    case 'canceled':
      return 'Cancelado'
    case 'refunded':
      return 'Devolvido'
    case 'contested':
      return 'Contestado'
    default:
      return status
  }
}

export const validarCPF = (document: string): boolean => {
  // Remove caracteres não numéricos
  document = document.replace(/[^\d]+/g, '')

  // Valida CPF
  if (document.length === 11) {
    let soma = 0
    let resto: number

    if (document === '00000000000' ||
      document === '11111111111' ||
      document === '22222222222' ||
      document === '33333333333' ||
      document === '44444444444' ||
      document === '55555555555' ||
      document === '66666666666' ||
      document === '77777777777' ||
      document === '88888888888' ||
      document === '99999999999') {
      return false
    }

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(document.substring(i - 1, i)) * (11 - i)
    }

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11)) {
      resto = 0
    }

    if (resto !== parseInt(document.substring(9, 10))) {
      return false
    }

    soma = 0

    for (let i = 1; i <= 10; i++) {
      soma += parseInt(document.substring(i - 1, i)) * (12 - i)
    }

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11)) {
      resto = 0
    }

    if (resto !== parseInt(document.substring(10, 11))) {
      return false
    }

    return true // CPF válido
  }

  return false
}

export const validarCNPJ = (document: string): boolean => {
  // Valida CNPJ
  document = document.replace(/[^\d]+/g, '')

  if (document.length === 14) {
    let tamanho = document.length - 2
    let numeros = document.substring(0, tamanho)
    const digitos = document.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--
      if (pos < 2) {
        pos = 9
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

    if (resultado !== parseInt(digitos.charAt(0))) {
      return false
    }

    tamanho += 1
    numeros = document.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--
      if (pos < 2) {
        pos = 9
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

    if (resultado !== parseInt(digitos.charAt(1))) {
      return false
    }

    return true // CNPJ válido
  }

  return false // Tamanho inválido para CPF ou CNPJ
}

export const getBadgeInvoiceStatus = (status: string) => {
  switch ((status ?? '').toLowerCase()) {
    case 'pending':
      return <Badge color='light-warning'>Pendente</Badge>
    case 'paid':
      return <Badge color='light-success'>Pago</Badge>
    case 'canceled':
      return <Badge color='light-danger'>Cancelado</Badge>
    default:
      return <Badge color='light-primary'>{status}</Badge>
  }
}

export const getBadgeBankingBilletStatus = (status: string) => {
  switch ((status ?? '').toLowerCase()) {
    case 'paid':
      return <Badge color='light-success'>Pago</Badge>
    case 'pending':
      return <Badge color='light-warning'>Em processamento</Badge>
    case 'waiting':
      return <Badge color='light-warning'>Em aberto</Badge>
    case 'expired':
      return <Badge color='light-danger'>Vencido</Badge>
    case 'failed':
      return <Badge color='light-danger'>Falha</Badge>
    case 'canceled':
      return <Badge color='light-danger'>Cancelado</Badge>
    case 'refunded':
      return <Badge color='light-danger'>Devolvido</Badge>
    case 'contested':
      return <Badge color='light-danger'>Contestado</Badge>
    case 'unpaid':
      return <Badge color='light-danger'>Não pago</Badge>
    default:
      return <Badge color='light-primary'>{status}</Badge>
  }
}

export const getCreditAnalisysType = (type: string | undefined) => {
  switch (type?.toLowerCase()) {
    case 'preapproved':
      return 'Pré-aprovada'
    case 'fullanalysis':
      return 'Análise completa'
    default:
      return 'Não informado'
  }
}

export const getAccountTypeLabel = (type: string) => {
  switch (type) {
    case 'pix':
      return 'Pix'
    case 'cc':
      return 'Conta Corrente'
    case 'ci':
      return 'Conta Investidor'
    case 'cp':
      return 'Conta Poupança'
    default:
      return 'Não registrado'
  }
}

export const getpixKeyTypeLabel = (pixKeyType: string) => {
  switch (pixKeyType) {
    case 'cpf':
      return 'CPF'
    case 'cnpj':
      return 'CNPJ'
    case 'phone':
      return 'Telefone'
    case 'email':
      return 'Email'
    case 'random':
      return 'Aleatória'
    default:
      return ''
  }
}

export const getBadgeStatusFinancialPlanningAI = (status: string) => {
  if (!status) {
    return null
  }

  switch (status.toLowerCase()) {
    case 'completado':
      return <Badge color='light-success'>{status}</Badge>
    case 'em processamento':
      return <Badge color='light-warning'>{status}</Badge>
    case 'buscando dados do openfinance':
      return <Badge color='light-primary'>{status}</Badge>
    case 'gerando planejamento com ia':
      return <Badge color='light-info'>{status}</Badge>

    default:
      return <Badge color='light-primary'>{status}</Badge>
  }
}
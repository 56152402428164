import { takeEvery, put, select } from 'redux-saga/effects'

function* initApp(): Generator<any> {
  const location: any = yield select(state => state.router.location)

  const params = new URLSearchParams(location.search) 

  const array = Array.from(params.entries())

  yield put({ type: 'ADD_ITEMS', data: array }) 
}


function* mySaga() {
  yield takeEvery('INIT_APP', initApp)
}

export default mySaga

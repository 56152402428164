// ** React Imports
import { useState, useRef } from 'react'

// ** Vertical Menu Items Array
import navigation from 'navigation'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'

// ** Vertical Menu Components

const Sidebar = (props: any) => {
  const {
    menuCollapsed,
    routerProps,
    menu,
    currentActiveItem,
    skin
  } = props
  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [activeItem, setActiveItem] = useState(null)

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false)

  // ** Ref
  const shadowRef = useRef<any>(null)

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    if (menuCollapsed) {
      setMenuHover(true)
    }
  }

  // ** Scroll Menu
  const scrollMenu = (container: any) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.add('d-block')
      }
    } else if (shadowRef.current.classList.contains('d-block')) {
      shadowRef.current.classList.remove('d-block')
    }
  }

  return (
    <div
      className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
        expanded: menuHover || menuCollapsed === false,
        'menu-light': skin !== 'semi-dark' && skin !== 'dark',
        'menu-dark': skin === 'semi-dark' || skin === 'dark'
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => setMenuHover(false)}
    >
      {menu ? (
        menu(props)
      ) : (
        <>
          {/* Vertical Menu Header */}
          <div className='bg-red'>
            <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
          </div>
          {/* Vertical Menu Header Shadow */}
          <div className='shadow-bottom' ref={shadowRef} />
          {/* Perfect Scrollbar */}
          <PerfectScrollbar
            className='main-menu-content'
            options={{ wheelPropagation: false }}
            onScrollY={container => scrollMenu(container)}
          >
            <ul className='navigation navigation-main'>
              <VerticalNavMenuItems
                items={navigation}
                groupActive={groupActive}
                setGroupActive={setGroupActive}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                groupOpen={groupOpen}
                setGroupOpen={setGroupOpen}
                routerProps={routerProps}
                menuCollapsed={menuCollapsed}
                menuHover={menuHover}
                currentActiveItem={currentActiveItem}
              />
            </ul>
          </PerfectScrollbar>
        </>
      )}
    </div>
  )
}

export default Sidebar

import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import rootReducer from 'redux/reducers'
import { filterMiddleware } from 'redux/actions/filters'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import mySaga from 'redux/actions/saga'
import { createBrowserHistory } from 'history'

// ** init middleware
export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const middlewares = [thunk, createDebounce(), sagaMiddleware, filterMiddleware, routerMiddleware(history)]

// ** Create store
const store = createStore(rootReducer(history), applyMiddleware(...middlewares))

sagaMiddleware.run(mySaga)
store.dispatch({type: 'INIT_APP'})

export default store

import { RestApi } from 'resources/services/interfaces'
import { toQueryString } from 'utils/service'
import { FilterBusiness, FilterPerson } from './interfaces'

const customerService = (_api: RestApi) => ({
  listPersons: async (filter: FilterPerson, config?: any) => _api.get(`/backoffice/persons?${toQueryString(filter)}`, config),
  getPerson: async (personId: string) => _api.get(`/backoffice/persons/${personId}`),
  getPersonInfoEssential: async (document: string) => _api.get(`/backoffice/persons/${document}/essential-info`),
  getAvailableAmount: async (customerId: string) =>
    _api.get(`/backoffice/account/customers/${customerId}/available-amounts`),
  requestFilling: async (customerId: string) => _api.put(`/backoffice/customers/${customerId}/request-financial-moment`, {}),
  updatePerson: async (personId: string, plannerId: string, data: any) => _api.patch(`/backoffice/persons/${personId}`, data, { changeResponsable: plannerId }),
  revokeAccess: async (personId: string) => {
    const userDataLocal = localStorage.getItem('userData')
    const userData = userDataLocal ? JSON.parse(userDataLocal) : {}
    _api.post(`/backoffice/persons/${personId}/revoke-access`, null, { changeResponsable: userData?.customerId })
  },
  restoreAccess: async (personId: string) => {
    const userDataLocal = localStorage.getItem('userData')
    const userData = userDataLocal ? JSON.parse(userDataLocal) : {}
    _api.post(`/backoffice/persons/${personId}/restore-access`, null, { changeResponsable: userData?.customerId })
  },
  getKycDocumentRejections: async (document?: string, name?: string) => _api.get(`/backoffice/persons/kyc/document-rejection?document=${document}&name=${name}`),
  kycDocumentRelease: async (personId?: string) => _api.put(`/backoffice/persons/${personId}/kyc/document-release`, {}),
  changeMobilePhone: async (personId: string, data: any, responsible: string) => _api.put(`/backoffice/persons/${personId}/device-number`, data, { changeResponsable: responsible }),
  changeEmail: async (personId: string, data: any, responsible: string) => _api.put(`/backoffice/persons/${personId}/update-email`, data, { changeResponsable: responsible }),
  syncPersonHelpCenterData: async (personId: string) => _api.post(`/backoffice/persons/${personId}/sync-help-center`),
  blockReasons: async (personId: string) => _api.get(`/backoffice/persons/${personId}/block-reasons`),
  listProductsEmployee: async (customerId: string) => _api.get(`/backoffice/subscriptions/customers/${customerId}`),
  readmissionEmployee: async (data: any) => _api.post('/backoffice/customers/employee-readmission', data),
  listBusinesses: async (filter: FilterBusiness) => _api.get(`/backoffice/business?${toQueryString(filter)}`),
  getBusiness: async (businessId: string) => _api.get(`/backoffice/business/${businessId}`),
  onboardingBusiness: async (data: any, headers: any) => _api.post('/backoffice/business/onboarding', data, headers),
  getDepartments: async (businessId: string) => _api.get(`backoffice/business/${businessId}/departments`),
  getBusinessSnapshot: async (businessId: string) => _api.get(`backoffice/business/${businessId}/details`),
  editBusiness: async (businessId: string, data: any, headers: any) => _api.patch(`backoffice/business/${businessId}/edit`, data, headers)
})

export default customerService
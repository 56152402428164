import classnames from 'classnames'
import { FC } from 'react'
import { SpinnerProps } from './interfaces'
import './styles.scss'

const Spinner: FC<SpinnerProps> = ({size, color, style}) => (
  <div className='fallback-spinner centered vh-5' style={style}>
    <div className={classnames('loading no-top', {
      'size-sm': size === 'sm',
      'size-md': size === 'md',
      'size-lg': size === 'lg'
    })} style={{color: `${color} !important`}}>
      <div className='effect-1 effects' />
      <div className='effect-2 effects' />
      <div className='effect-3 effects' />
    </div>
  </div>
)

export default Spinner

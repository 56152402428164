import { push } from 'connected-react-router'

const getItemPath = (items: any[]) => {
  const params = new URLSearchParams()
  const uniqueItems = new Map(items.map((item) => [item[0], item[1]]))

  uniqueItems.forEach((value, key) => {
    params.set(key, value)
  })

  return params.toString()
}

const clearFilters = () => (dispatch: (arg0: { type: string }) => void) => {
  dispatch({ type: 'CLEAR_FILTERS' })
}

const setItemPath = (value: any) => (dispatch: (arg0: { type: string; data: any }) => any) => {
  dispatch({ type: 'ADD_ITEM', data: value })
}

const filterMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    store.dispatch({ type: 'CLEAR_ITEMS' })
  }

  if (action.type === 'ADD_ITEM') {
    const state = store.getState()
    const updatedFilters = state.filters.filter((item: any) => item[0] !== action.data[0])
    updatedFilters.push(action.data)

    const itemsPath = getItemPath(updatedFilters)
    store.dispatch(push(`${state.router.location.pathname}?${itemsPath}`))
  }
  next(action)
}


export { getItemPath, filterMiddleware, setItemPath, clearFilters }

import { Archive, BarChart2, FileText, Info } from 'react-feather'

export default [
  {
    header: 'Clientes'
  },
  {
    id: 'businesses',
    title: 'Negócios',
    icon: <Info />,
    navLink: '/negocios',
  },
  {
    id: 'payrolls',
    title: 'Folhas',
    icon: <Archive />,
    navLink: '/empresas/folhas',
  },
  {
    id: 'analysis',
    title: 'Análises',
    icon: <BarChart2 />,
    navLink: '/empresas/analises',
  },
  {
    id: 'invoices',
    title: 'Faturas',
    icon: <FileText />,
    navLink: '/empresas/faturas',
  }
]
// ** Redux Imports
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import companies from './companies'
import filters from './filters'
import survey from './survey'
import order from './order'

const rootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  filters,
  auth,
  navbar,
  layout,
  companies,
  survey,
  order
})
export default rootReducer

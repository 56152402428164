import { FC, useEffect, useState } from 'react'
import { NavItem, NavLink } from 'reactstrap'
import { Menu } from 'react-feather'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { handleCompanyList, handleCompanySelected } from 'redux/actions/companies'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from 'providers/Api'
import { toast } from 'react-toastify'
import UserDropdown from './UserDropdown'
import { NavbarProps } from './interfaces'

const ThemeNavbar: FC<NavbarProps> = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const userDataLocal = localStorage.getItem('userData')
  const userData = userDataLocal ? JSON.parse(userDataLocal) : {}
  const store = useSelector<any, any>(state => state)
  const { companyService } = useApi()
  const [companiesOptions, setCompanyOptions] = useState(store?.companies?.companyList)
  const [selectedCompany, setSelectedCompany] = useState([{ value: 'all', label: 'Todas as empresas' }])

  const history = useHistory()

  const changeCompany = (e: any) => {
    dispatch(handleCompanySelected(e.value))
    setSelectedCompany([{ value: e.value, label: e.label }])
  }

  const formatGroupLabel = (data: any) => (
    <div className='d-flex justify-content-between align-center'>
      <strong>
        <span>{data.label}</span>
      </strong>
    </div>
  )

  const setCompanies = () => {
    const companyList = [{ label: '', options: [{ value: 'all', label: 'Todas as Empresas' }] }]

    if (companyService) {
      setLoading(true)
      companyService.getCompanies()
        .then((res: any) => {
          const matrix: any = []
          const groups: any = []
          const bussinesesUnit: any = []

          if (res) {
            res.data.forEach((item: any) => {
              if (item.type.toLowerCase() === 'matrix') {
                matrix.push({ value: item.companyGroupId, label: item.name })
              }

              if (item.type.toLowerCase() === 'group') {
                groups.push({ value: item.companyID ?? item.companyId, label: item.name })
              }

              if (item.type.toLowerCase() === 'bu') {
                bussinesesUnit.push({ value: item.companyGroupId, label: item.name })
              }
            })
          }

          companyList.push({ label: 'Matrizes', options: matrix })
          companyList.push({ label: 'Grupos', options: groups })
          companyList.push({ label: 'Filiais', options: bussinesesUnit })
        }).catch((err) => {
          console.error(err)
          toast.error('Erro ao buscar empresas')
        }).finally(() => setLoading(false))
    }
    dispatch(handleCompanyList(companyList))
    dispatch(handleCompanySelected(userData?.companyData?.companyGroupId))
    setCompanyOptions(companyList)
    setSelectedCompany(companyList[0].options)
  }

  useEffect(() => {
    if (!store.companies.companylist || store.companies.companyList.length === 0) {
      setCompanies()
    }
  }, [history.location.pathname])

  return (
    <>
      <ul className='navbar-nav d-xl-none d-flex align-items-center'>
        <NavItem className='mobile-menu mr-auto'>
          <NavLink className='nav-menu-main menu-toggle hidden-xs is-active'>
            <Menu className='ficon' />
          </NavLink>
        </NavItem>
      </ul>
      <ul className='nav navbar-nav align-items-center ml-auto'>
        {history.location.pathname.split('/')[1] !== 'empresas' && (
          <div style={{ width: '250px', marginLeft: 10, marginRight: 5 }}>
            <Select
              value={selectedCompany}
              isLoading={loading}
              className='react-select'
              classNamePrefix='select'
              formatGroupLabel={formatGroupLabel}
              options={companiesOptions}
              isClearable={false}
              onChange={changeCompany}
            />
          </div>
        )}
        <UserDropdown />
      </ul>
    </>
  )
}

export default ThemeNavbar

import { FC, useEffect } from 'react'
import { NavLink, useLocation, matchPath } from 'react-router-dom'
import { Badge } from 'reactstrap'
import classnames from 'classnames'
import { search, getAllParents } from 'utils/layout'
import navigation from 'navigation'
import { VerticalNavMenuLinkProps } from './interfaces'

const VerticalNavMenuLink: FC<VerticalNavMenuLinkProps> = ({
  item,
  setGroupActive,
  activeItem,
  setActiveItem,
  setGroupOpen,
  toggleActiveGroup,
  parentItem,
  routerProps,
  currentActiveItem
}: any) => {
  const LinkTag = item.externalLink ? 'a' : NavLink

  // ** URL Vars
  const location = useLocation()
  const currentURL = location.pathname

  // ** To match path
  const match = matchPath(currentURL, {
    path: `${item.navLink}/:param`,
    exact: true,
    strict: false
  })

  // ** Search for current item parents
  const searchParents = (navigationSearch: any, currentURLSearch: any) => {
    const parents = search(navigationSearch, currentURLSearch, routerProps) // Search for parent object
    // Parents Object to Parents Array
    return getAllParents(parents, 'id')
  }

  // ** URL Vars
  const resetActiveGroup = (navLink: any) => {
    const parents = search(navigation, navLink, match)
    toggleActiveGroup(item.id, parents)
  }

  // ** Reset Active & Open Group Arrays
  const resetActiveAndOpenGroups = () => {
    setGroupActive([])
    setGroupOpen([])
  }

  // ** Checks url & updates active item
  useEffect(() => {
    if (currentActiveItem !== null) {
      setActiveItem(currentActiveItem)
      const arr = searchParents(navigation, currentURL)
      setGroupActive([...arr])
    }
  }, [location])

  return (
    <li
      className={classnames({
        'nav-item': !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem
      })}
    >
      <LinkTag
        className='d-flex align-items-center'
        target={item.newTab ? '_blank' : undefined}
        to={item.navLink || '/'}
        isActive={(itemMatch) => {
          if (!itemMatch || !itemMatch.isExact) {
            return false
          }

          if (itemMatch.url && itemMatch.url !== '' && itemMatch.url === item.navLink) {
            currentActiveItem = item.navLink
          }
          return true
        }}
        /* eslint-enable */
        onClick={e => {
          if (!item.navLink.length) {
            e.preventDefault()
          }
          parentItem ? resetActiveGroup(item.navLink) : resetActiveAndOpenGroups()
        }}
      >
        {item.icon}
        <span className='menu-item text-truncate'>{item.title}</span>

        {item.badge && item.badgeText ? (
          <Badge className='ml-auto mr-1' color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </LinkTag>
    </li>
  )
}

export default VerticalNavMenuLink

// ** Initial State
const initialState = {
  insuranceId: null
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const orderReducer = (state: any = initialState, action: any) => {
  if (action?.type === 'HANDLE_SET_INSURANCE')
    return { ...state, insuranceId: action.value }

  if (action?.type === 'HANDLE_CLEAR_INSURANCE')
    return { ...state, ...initialState }

  return state
}

export default orderReducer
const getDefaultItems = () => {
  const params = new URLSearchParams(window.location.hash.split('?')[1])
  return Array.from(params.entries())
    .filter(([key]) => key === 'item')
    .map(([, value]) => value)
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const items = (state = getDefaultItems(), action: any) => {
  switch (action?.type) {
    case 'ADD_ITEM':
      return [...state.filter(item => item[0] !== action.data[0]), action.data]
    case 'ADD_ITEMS':
      return [...action.data]
    case 'CLEAR_ITEMS':
      return []
    default:
      return state
  }
}

export default items
const Footer = () => (
  <p className='clearfix mb-0 mt-2'>
    <span className='float-md-left d-block d-md-inline-block mt-25'>
        COPYRIGHT © {new Date().getFullYear()}{' '}
      <a href='https://pillapoupe.com/' target='_blank' rel='noopener noreferrer'>
          Pilla
      </a>
      <span className='d-none d-sm-inline-block'>, All rights Reserved</span>
    </span>
  </p>
)

export default Footer

import { createContext, FC, useContext, useState } from 'react'
import { createApi, createPartnersApi } from 'resources/api'
import {
  orderService,
  companyService,
  customerService,
  financialPlanningService,
  subscriptionService,
  npsService,
  financialMomentService,
  investmentRecurringService,
  surveyService,
  featureService,
  pipeDreamService,
  payrollService,
  bankingBilletService,
  invoiceService,
  insuranceService
} from 'resources/services'
import { ApiProviderProps, ApiProviderState } from './interfaces'

const ApiContext = createContext<Partial<ApiProviderState>>({})

const ApiProvider: FC<ApiProviderProps> = ({ children, environmentConfiguration }) => {
  const { REACT_APP_BACKEND_URL, REACT_APP_CREATE_AZURE_ISSUE, REACT_APP_LIST_AZURE_ISSUE } = environmentConfiguration

  const [apis] = useState<ApiProviderState>(() => {
    const api = createApi(REACT_APP_BACKEND_URL)
    const partnersApi = createPartnersApi(REACT_APP_LIST_AZURE_ISSUE, REACT_APP_CREATE_AZURE_ISSUE)

    return {
      orderService: orderService(api),
      companyService: companyService(api),
      customerService: customerService(api),
      financialPlanningService: financialPlanningService(api),
      subscriptionService: subscriptionService(api),
      npsService: npsService(api),
      financialMomentService: financialMomentService(api),
      investmentRecurringService: investmentRecurringService(api),
      surveyService: surveyService(api),
      featureService: featureService(api),
      pipeDreamService: pipeDreamService(partnersApi),
      payrollService: payrollService(api),
      bankingBilletService: bankingBilletService(api),
      invoiceService: invoiceService(api),
      insuranceService: insuranceService(api)
    }
  })

  const value = apis
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

const useApi = () => {
  const context = useContext(ApiContext)
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiProvider')
  }
  return context
}

export default ApiProvider
export { useApi }